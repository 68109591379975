<template>
  <div class="loading-box">
    <el-progress type="circle" :width="138" :stroke-width="8" :percentage="analysisPercentage" color="#2B7FE2"></el-progress>
    <p>
      上传成功，AI分析中，请稍后…
    </p>
  </div>
</template>

<script>
// @ is an alias to /src
import * as daikon from 'daikon'
import {
  getAnalyseProgress
} from '@/api/modules/knee-system'
export default {
  name: 'loading',
  components: {
  },
  props: {
    id: String,
    dicoms: Array
  },
  data () {
    return {
      analysisPercentage: 0,
      setTimeoutEvent: null,
      analysisFinish: false
    }
  },
  watch: {
    analysisFinish (n) {
      if (n) {
        this.getCurAnalyseProgress()
      }
    }
  },
  mounted () {
    this.setTimeoutEvent = setTimeout(() => {
      this.direct()
    }, 1000)
  },
  beforeDestroy () {
    if (this.setTimeoutEvent) clearTimeout(this.setTimeoutEvent)
  },
  methods: {
    getfile (url, type) {
      return new Promise(function (resolve, reject) {
        const http = new XMLHttpRequest()
        http.open('Get', url, true)
        http.responseType = 'arraybuffer'
        http.onload = function () {
          resolve({ response: http.response, type })
        }
        http.onerr = function (e) {
          reject(e)
        }
        http.send()
      })
    },
    // 解析方向
    direct () {
      if (sessionStorage.getItem('erroeMsg') !== 'AI分析') sessionStorage.setItem('erroeMsg', 'AI分析')
      const coronalMap = this.dicoms.coronalUrls.map(
        dicom => this.getfile(dicom, 1)
      )
      const sagittalMap = this.dicoms.sagittalUrls.map(
        dicom => this.getfile(dicom, 0)
      )
      Promise.all([...coronalMap, ...sagittalMap]).then(
        res => {
          for (let i = 0; i < res.length; i++) {
            const dicom = res[i]
            const parser = new daikon.Parser()
            const data = new DataView(dicom.response)
            const img = parser.parse(data)
            const imgdirc = img.getAcquiredSliceDirection()
            if (imgdirc !== dicom.type) {
              if (sessionStorage.getItem('loading')) sessionStorage.removeItem('loading')
              this.$router.replace('/error')
              console.log('can\'t parse dcm direction')
              return
            }
          }
          this.analysisFinish = true
        }
      )
    },
    // 文件解析
    getCurAnalyseProgress () {
      getAnalyseProgress(this.id).then(
        res => {
          this.analysisPercentage = res.data.progress
          if (this.analysisPercentage !== 100) {
            this.setTimeoutEvent = setTimeout(() => {
              this.getCurAnalyseProgress()
            }, 1000)
          } else {
            this.setTimeoutEvent = setTimeout(() => {
              if (sessionStorage.getItem('loading')) sessionStorage.removeItem('loading')
              this.$router.replace(`/analysis?id=${this.id}`)
            }, 1000)
          }
        }
      ).catch(err => {
        console.log(err, 'getAnalyseProgress')
        this.$router.replace('/error')
      })
    }
  }
}

</script>
