<template>
  <div class="loading">
    <Header></Header>
    <upload-step
      v-if="!status"
      @next="next"
    ></upload-step>
    <analysis-step
      v-else
      :id="id"
      :dicoms="dicoms"
    ></analysis-step>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '../../components/KneeHeader.vue'
import UploadStep from './upload.vue'
import AnalysisStep from './analysis.vue'
export default {
  name: 'loading',
  components: {
    Header,
    UploadStep,
    AnalysisStep
  },
  beforeRouteEnter (to, from, next) {
    if (sessionStorage.getItem('loading')) {
      sessionStorage.removeItem('loading')
      next({ path: '/error', replace: true })
    } else {
      next()
    }
  },
  beforeRouteLeave (to, from, next) {
    if (!sessionStorage.getItem('loading')) {
      next()
      return
    }
    const answer = window.confirm('离开将取消当前分析任务！')
    if (answer) {
      sessionStorage.removeItem('loading')
      next()
    } else {
      next(false)
    }
  },
  data () {
    return {
      id: null,
      status: 0,
      dicoms: {}
    }
  },
  created () {
    const cid = this.$route.query.id
    if (cid) {
      this.status = 1
      this.id = cid
      const { coronalUrls, sagittalUrls } = this.$store.state
      this.dicoms = {
        coronalUrls,
        sagittalUrls
      }
    }
  },
  mounted () {
    window.onbeforeunload = function (e) {
      return ''
    }
  },
  destroyed () {
    if (sessionStorage.getItem('loading')) sessionStorage.removeItem('loading')
    window.onbeforeunload = null
  },
  methods: {
    next (urls, id) {
      this.status = 1
      this.id = id
      this.dicoms = urls
    }
  }
}

</script>

<style lang="less" scoped>
.loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-top: 48px;
  /deep/.loading-box {
    margin-top: -10%;
    text-align: center;
  }
  /deep/p {
    margin-top: 40px;
    font-size: 24px;
  }
}
/deep/.el-progress--circle .el-progress__text {
  font-size: 30px !important;
}
</style>
