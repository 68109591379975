<template>
  <div class="loading-box">
    <el-progress type="circle" :width="138" :stroke-width="8" :percentage="uploadedPercentage" color="#2B7FE2"></el-progress>
    <p>
      上传中，请稍后…
    </p>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from 'vuex'
import { qiniuToken } from '@/api'
import { uploader } from '@/utils/uploader'
import {
  postAnalyseUpload
} from '@/api/modules/knee-system'
export default {
  name: 'loading',
  components: {
  },
  props: {
  },
  data () {
    return {
      id: null,
      coronalUrls: [],
      sagittalUrls: []
    }
  },
  computed: {
    ...mapState({
      coronalFiles: state => state.guanList,
      sagittalFiles: state => state.shiList
    }),
    // 上传进度
    uploadedPercentage () {
      if (!this.coronalFiles.length) {
        return this.sagittalPercentage
      }
      if (!this.sagittalFiles.length) {
        return this.coronaPercentage
      }
      const res = this.sagittalPercentage / 2 + this.coronaPercentage / 2
      return parseInt(res)
    },
    // 冠状位上传进度
    coronaPercentage () {
      const total = this.coronalFiles.length
      if (!total) return 0
      const res = this.coronalUrls.length / total * 100
      return parseInt(res)
    },
    // 矢状位上传进度
    sagittalPercentage () {
      const total = this.sagittalFiles.length
      if (!total) return 0
      const res = this.sagittalUrls.length / total * 100
      return parseInt(res)
    }
  },
  watch: {
    uploadedPercentage (n, o) {
      if (n === 100) {
        // 用id解析
        const uploadRes = {
          id: this.id
        }
        if (this.coronalUrls.length) uploadRes.coronalImages = this.coronalUrls
        if (this.sagittalUrls.length) uploadRes.sagittalImages = this.sagittalUrls
        postAnalyseUpload(uploadRes).then(
          res => {
            const dicoms = {
              coronalUrls: this.coronalUrls,
              sagittalUrls: this.sagittalUrls
            }
            this.$emit('next', dicoms, this.id)
          }
        ).catch(
          err => {
            this.error()
            console.log(err, 'postAnalyseUpload')
          }
        )
      }
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      sessionStorage.setItem('loading', 'true')
      sessionStorage.setItem('erroeMsg', '上传')
      this.uploadDicom('coronal')
      this.uploadDicom('sagittal')
    },
    // 文件上传
    async uploadDicom (name) {
      const currFiles = this[`${name}Files`]
      if (!currFiles.length) return
      try {
        const res = await qiniuToken({ path: `kneeJoint/${name}` })
        const { token, id } = res.data
        if (!this.id) this.id = id
        currFiles.forEach(file => {
          uploader({ file: file, token }).then(
            res => {
              this[`${name}Urls`].push(res.url)
            }
          )
        })
      } catch (err) {
        console.log(err, 'qiniuToken/uploadQiNiu')
        this.error()
      }
    },
    error () {
      // const msg = this.status ? 'AI分析' : '上传'
      // sessionStorage.setItem('erroeMsg', msg)
      this.$router.replace('/error')
    }
  }
}

</script>
